import React, { useEffect, useState } from "react";
import LocationSearch from "./LocationSearch";
import Chart from "./Chart";
import StationInfo from "./StationInfo";
import Logo from 'images/fish.png';

const Dashboard = ({
  initLocation,
  initStationId,
  initStationName,
  initStationCoords,
  initStationTimeZone,
  initTidePrediction
}) => {
  const [location, setLocation] = useState(initLocation);
  const [stationId, setStationId] = useState(initStationId);
  const [stationName, setStationName] = useState(initStationName);
  const [stationCoords, setStationCoords] = useState(initStationCoords || []);
  const [stationTimeZone, setStationTimeZone] = useState(initStationTimeZone);
  const [tidePrediction, setTidePrediction] = useState(initTidePrediction || []);

  const [status, setStatus] = useState('loading');

  const handleLocationChange = (newLocation) => setLocation(newLocation);

  useEffect(() => {
    setStatus('loading');

    fetch(`/api/v1/water_level?location=${location}&range=36`)
      .then(res => res.json())
      .then(
        (result) => {
          setStationId(result.station_id);
          setStationName(result.station_name);
          setStationCoords([result.lat, result.lon]);
          setStationTimeZone(result.station_time_zone);
          setTidePrediction(result.tide_prediction);

          setStatus('success');
        },
        (error) => {
          setStationId(undefined);
          setStationName(undefined);
          setStationCoords([]);
          setStationTimeZone(undefined);
          setTidePrediction([]);

          setStatus('error');
        }
      );
  }, [location]);

  return (
    <>
      <section className="hero is-light">
        <div className="hero-body py-3">
          <div className="container">
            <div className="level">
              <div className="level-left">
                <a className="level-item" href="/">
                  <img src={Logo} style={{ height: '50px' }} className="mr-2"/>
                  <p>BlubTides</p>
                </a>
              </div>
              <div className="level-right is-hidden-mobile">
                <div className="level-item">
                  <LocationSearch
                    initLocation={location}
                    onLocationChange={handleLocationChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section is-hidden-tablet py-3">
        <LocationSearch
          initLocation={location}
          onLocationChange={handleLocationChange}
        />
      </section>

      <section className="section">
        {status === 'loading' && (
          <div className="container">
            <progress className="progress is-large" max="100"></progress>
          </div>
        )}
        {tidePrediction && status === 'success' && (
          <>
            <div className="container">
              <StationInfo
                stationName={stationName}
                stationId={stationId}
                stationCoords={stationCoords}
              />
            </div>
            <div className="container">
              <Chart
                tidePrediction={tidePrediction}
                stationTimeZone={stationTimeZone}
              />
            </div>
          </>
        )}
        {!tidePrediction && status === 'success' && (
          <div className="container">
            <h1>No Data Found</h1>
          </div>
        )}
      </section>
    </>
  );
};

export default Dashboard;
