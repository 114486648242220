import React, { useState } from "react";

const LocationSearch = ({
  initLocation,
  onLocationChange
}) => {
  const [location, setLocation] = useState(initLocation);
  const handleChange = (event) => setLocation(event.target.value);

  const handleSubmit = (event) => {
    onLocationChange(location);
    event.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="field has-addons is-justify-content-center">
        <div className="control">
          <input
            placeholder="Location (ex. 'Cape Cod')"
            className="input"
            value={location}
            onChange={handleChange}
          />
        </div>
        <div className="control">
          <input
            className="button"
            style={{ color: 'white', 'background-color': '#4b77be' }}
            type="submit"
            value="Search"
          />
        </div>
      </div>
    </form>
  );
};

export default LocationSearch;
