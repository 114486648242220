import React, { useEffect, useState } from "react";

const StationInfo = ({
  stationName,
  stationId,
  stationCoords
}) => {
  return (
    <div className="mb-3">
      <h5 className="is-size-5 has-text-centered-mobile">{stationName}</h5>
    </div>
  );
};

export default StationInfo;
