import React, { useEffect, useState } from "react";
import { Chart as ReactChart, Line } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';

ReactChart.pluginService.register({
  afterDraw: (chart, easing) => {
    if (chart.tooltip._active && chart.tooltip._active.length) {
      const activePoint = chart.controller.tooltip._active[0];
      const ctx = chart.ctx;
      const x = activePoint.tooltipPosition().x;
      const topY = chart.scales['y-axis-0'].top;
      const bottomY = chart.scales['y-axis-0'].bottom;

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#95a5a6';
      ctx.stroke();
      ctx.restore();
    }
  }
});

const Chart = ({
  tidePrediction,
  stationTimeZone
}) => {
  const today = new Date();
  const stationToday = new Date(today.toLocaleString('en-US', {
    timeZone: stationTimeZone
  }));

  const options = {
    legend: {
      display: false
    },
    animation: {
      duration: 500
    },
    tooltips: {
      callbacks: {
        title: (tooltipItem, data) => {
          const time = tidePrediction[tooltipItem[0]['index']].time;
          const date = new Date(time);

          return date.toLocaleString('en-US', {
            hour: '2-digit',
            minute: '2-digit'
          });
        },
        label: (tooltipItem, data) => ( `${data['datasets'][0]['data'][tooltipItem['index']]}ft` ),
        footer: (tooltipItem, data) => {
          const time = tidePrediction[tooltipItem[0]['index']].time;
          const date = new Date(time);

          return date.toDateString();
        }
      },
      displayColors: false,
      mode: 'index',
      intersect: false,
      xPadding: 10,
      yPadding: 10
    },
    hover: {
       mode: 'index',
       intersect: false
    },
    scales: {
      xAxes: [{ gridLines: { color: '#ecf0f1' } }],
      yAxes: [{ gridLines: { color: '#ecf0f1' } }]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: stationToday.getHours() + (stationToday.getMinutes() / 260),
          borderWidth: 1,
          borderColor: '#be90d4',
          label: {
            content: today.toLocaleString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              timeZone: stationTimeZone
            }),
            enabled: true,
            position: 'top'
          }
        }
      ]
    }
  };

  const data = {
    labels: tidePrediction.map((t) => {
      const date = new Date(t.time);

      return date
        .toLocaleString('en-US', { hour: 'numeric' })
        .replace(/ /g, '')
        .toLowerCase().slice(0, -1);
    }),
    datasets: [
      {
        data: tidePrediction.map((t) => t.level),
        pointRadius: 0,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 0,
        pointHoverBackgroundColor: '#4b77be',
        borderWidth: 0,
        backgroundColor: '#4b77be'
      }
    ]
  };

  return (
    <Line
      options={options}
      data={data}
    />
  );
};

export default Chart;
